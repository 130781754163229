import { HttpClient, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationProvider } from '../../providers/authentication/authentication';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceAppointmentsProvider {

  constructor(private http: HttpClient, private authenticationService: AuthenticationProvider) {}
  /**
   * @description : API to fetch service appointments
   */
  getServiceAppointments() {
    return this.http.get(`${environment.apiUrl}me/service-appointments`).pipe(map((resp: Response) => {
      return resp;
    }));
  }
  /**
   * 
   * @param id 
   * @description Get service appointment detail by Id
   */
  getServiceAppointmentById(id) {
    return this.http.get(`${environment.apiUrl}me/service-appointments/${id}`).pipe(map((resp: Response) => {
      return resp;
    }));
  }
  /**
   *
   * @param data
   * @description API call to request service appointment to vendor
   */
  insertServiceAppointment(data) {
    return this.http.post<HttpResponse<any>>(`${environment.apiUrl}me/service-appointments/request`, data, { observe: 'response' })
    .pipe(
      map((resp: HttpResponse<any>) => {
        return resp;
      }));
  }
  updateStatus(id, data) {
    return this.http.put(`${environment.apiUrl}me/service-appointments/${id}/update-status`, data, { observe: 'response' });
  }
  /**
   *
   * @param key
   * @description API to get service-appointment detail for vendor based on requestkey
   */
  getServiceAppointmentBasedRequestKey(key) {
    return this.http.get(`${environment.apiUrl}ari/service-appointments/${key}`).pipe(map((resp: Response) => {
      return resp;
    }));
  }
/**
   *
   * @param key
   * @description API call to fetch service-card detail based on session key
   */
  getMaintanceCardBySessionKey(key) {
    return this.http.get(`${environment.apiUrl}ari/assets/maintenance-service-card?sessionkey=${key}`).pipe(map((resp: Response) => {
      return resp;
    }));
  }
  /**
   *
   * @param sessionkey
   * @param data
   * @description: API call to cancel all propsed appointments
   */
  denyAllApointment(sessionkey, data) {
    return this.http.put(`${environment.apiUrl}ari/service-appointments/${sessionkey}/update-status`, data, { observe: 'response' });
  }
  /**
   *
   * @param sessionkey
   * @param data
   * @description API call to accept a propsed time
   */
  acceptAppointment(sessionkey, data) {
      // tslint:disable-next-line: max-line-length
      return this.http.post(`${environment.apiUrl}ari/service-appointments/accept-time/${sessionkey}/comment`, data, { observe: 'response' })
      .pipe(
        map((resp: HttpResponse<any>) => {
          return resp;
        }));
    }
  }
